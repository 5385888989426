import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Post from "../components/blog/post-summary";
import Pagination from "../components/blog/pagination";
import SEO from "../components/seo";

const BlogIndexPage = ({ data, pageContext, location }) => {
  const prevPageLink = `${location.pathname.endsWith("/") ? location.pathname : location.pathname.substr(0, location.pathname.length-1)}${pageContext.currentPage === 2 ? pageContext.currentPage-1 : ""}`;
  const nextPageLink = `${location.pathname.substr(0, location.pathname.length-1)}${pageContext.currentPage === 1 ? "/" : ""}${pageContext.currentPage+1}`;

    return (
      <Layout>
        <SEO title={pageContext.pageTitle} />
        <div className="max-w-3xl px-4 py-8 bg-cover bg-center mx-auto">
            <h1 className="text-tertiary text-5xl">{pageContext.pageTitle || "blog"}</h1>
            <div className="flex flex-row">
              <div className="mx-auto flex flex-col md:w-2/3 p-4">
              {data.posts.edges.map(({ node }, i) => (<Post key={`post_${i}`} node={node} />))}
              <Pagination prevLink={prevPageLink} nextLink={nextPageLink} current={pageContext.currentPage} total={pageContext.numPages} />
              </div>
            </div>
        </div>
      </Layout>
    );
};

export default BlogIndexPage;
export const query = graphql`
query ($skip: Int, $limit: Int, $category: [String], $tag: [String], $dateGte: Date, $dateLt: Date){
   posts: allMarkdownRemark(
     filter: {
      frontmatter: {
         draft: {ne: true},
         categories: { in: $category },
         tags: { in: $tag },
         date: {
           gt: $dateGte,
           lt: $dateLt
         }
      },
     }, 
     sort: {fields: frontmatter___date, order: DESC}, 
     limit: $limit,
     skip: $skip
    ) {
    edges {
      node {
        ...BlogPost
      }
    }
  }
}
`